import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { hatch } from 'ldrs';
import '../styles/LoadingPage.css';
import { getFunFacts } from '../utils/serverComm';

// Initialize the loader
hatch.register();

function LoadingPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { destinationCity, destinationCountry, duration } = location.state || {};
  const [facts, setFacts] = useState([]);
  const [currentFactIndex, setCurrentFactIndex] = useState(0);
  const [showFact, setShowFact] = useState(true);
  const { tripId } = useParams();

  useEffect(() => {
    console.log('LoadingPage mounted');
    console.log('Destination:', destinationCity, destinationCountry);
    console.log('Duration:', duration);

    // Retrieve trip status from sessionStorage
    const tripStatus = JSON.parse(sessionStorage.getItem('tripStatus'));

    if (tripStatus && tripStatus.tripId === tripId) {
      if (tripStatus.status === 'completed') {
        console.log('Trip already created, navigating to TravelPlanPage');
        navigate(`/trip/${tripId}/edit`);
        return;
      } else if (tripStatus.status === 'initiated') {
        console.log('Trip creation already in progress');
        fetchFunFacts(); // Optionally, you can check if other processes need to be resumed
      } else if (tripStatus.status === 'error') {
        console.log('Previous trip creation encountered an error, navigating to landing page');
        navigate('/');
        return;
      }
    } else {
      // No trip status found, possibly an invalid tripId or session expired
      console.log('Invalid trip status, navigating to landing page');
      navigate('/');
      return;
    }

    return () => {
      console.log('LoadingPage unmounted');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripId, destinationCity, destinationCountry, duration, navigate]);

  useEffect(() => {
    if (facts.length > 0) {
      console.log('Setting up fact rotation');
      const intervalId = setInterval(() => {
        setShowFact(false);
        setTimeout(() => {
          setCurrentFactIndex((prevIndex) => (prevIndex + 1) % facts.length);
          setShowFact(true);
        }, 500);
      }, 10000);

      return () => {
        console.log('Clearing fact rotation interval');
        clearInterval(intervalId);
      };
    }
  }, [facts]);

  // 2. Redirect to '/edit' after 40 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      console.log('40 seconds elapsed, navigating to edit page');
      navigate(`/trip/${tripId}/edit`);
    }, 60000); // 40000 milliseconds = 40 seconds

    return () => clearTimeout(timer);
  }, [navigate, tripId]);

  const fetchFunFacts = async () => {
    try {
      console.log('Fetching fun facts for', destinationCity, destinationCountry);
      const fetchedFacts = await getFunFacts(destinationCity, destinationCountry);
      console.log('Fetched facts:', fetchedFacts);
      if (fetchedFacts && fetchedFacts.length > 0) {
        setFacts(fetchedFacts);
        // Update trip status to 'funFactsFetched'
        sessionStorage.setItem('tripStatus', JSON.stringify({
          tripId,
          status: 'funFactsFetched',
        }));
      } else {
        console.log('No facts returned, setting default message');
        setFacts([{ fact: "Interesting facts about your destination are on the way!" }]);
        // Update trip status to 'funFactsFetched'
        sessionStorage.setItem('tripStatus', JSON.stringify({
          tripId,
          status: 'funFactsFetched',
        }));
      }
    } catch (error) {
      console.error('Error fetching fun facts:', error);
      setFacts([{ fact: "We're preparing some exciting information about your destination!" }]);
      // Update trip status to 'error'
      sessionStorage.setItem('tripStatus', JSON.stringify({
        tripId,
        status: 'error',
      }));
    }
  };

  // 1. Add a small text under the main heading
  return (
    <div className="loading-page">
      <h1>Creating your trip to {destinationCity}, {destinationCountry}</h1>
      <p className="refresh-warning">Please do not refresh the page.</p>
      <div className="animation-container">
        <l-hatch
          size="40"
          stroke="4"
          speed="4.3" 
          color="black"
        ></l-hatch>
      </div>
      <br/>
      <div className={`fact-container ${showFact ? 'show' : 'hide'}`}>
        {facts.length > 0 ? (
          <p className="fun-fact">{facts[currentFactIndex].fact}</p>
        ) : (
          <p>This may take a few moments...</p>
        )}
      </div>
    </div>
  );
}

export default LoadingPage;