import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/TriviaPage.css';
import { saveTriviaAnswers, getTriviaAnswers } from '../utils/serverComm';
import { trackEvent } from '../analytics';
import {
  FaPlaneDeparture,
  FaSmileBeam,
  FaUserAstronaut,
  FaFeatherAlt,
  FaPalette,
  FaCompass,
  FaMusic,
  FaBook,
  FaHeart,
  FaChild,
  FaTree,
  FaMountain,
  FaCity,
  FaUmbrellaBeach,
  FaGlobe,
  FaHiking,
  FaSpa,
  FaCamera,
  FaUtensils,
  FaVolleyballBall,
  FaGamepad,
  FaRunning,
  FaTheaterMasks,
  FaCocktail,
  FaUser,
  FaUsers,
  FaArrowRight,
  FaCheckCircle,
  FaHome as FaHomeIcon,
  FaShoppingBag,
  FaSwimmer,
  FaShip,
  FaBiking,
  FaHandsHelping,
  FaPaw,
  FaPlus,
} from 'react-icons/fa';
import { IconContext } from 'react-icons';
import CityAutocomplete from '../components/CityAutocomplete';

const STEPS = {
  WELCOME: 'welcome',
  BASICS: 'basics',
  MOOD: 'mood',
  DESTINATION: 'destination',
  ACTIVITY: 'activity',
  COMPANION: 'companion',
  ACCOMMODATION: 'accommodation',
  DINING: 'dining',
  INTERESTS: 'interests',
  THANKS: 'thanks',
};

const INTEREST_ICONS = {
  Beach: <FaUmbrellaBeach />,
  Hiking: <FaHiking />,
  "Adventure Sports": <FaRunning />,
  Theater: <FaTheaterMasks />,
  Museums: <FaPalette />,
  "Historical Tours": <FaCity />,
  "Spa / Wellness": <FaSpa />,
  Photography: <FaCamera />,
  "Cooking Classes": <FaUtensils />,
  "Fine Dining": <FaUtensils />,
  Nightlife: <FaCocktail />,
  "Wine Tasting": <FaGlobe />,
  Shopping: <FaShoppingBag />,
  "Water Sports": <FaSwimmer />,
  Cruises: <FaShip />,
  Cycling: <FaBiking />,
  Volunteering: <FaHandsHelping />,
  Wildlife: <FaPaw />,
};

const ACCOMMODATION_OPTIONS = [
  { id: 'luxury', label: 'Luxury Hotels', emoji: '🏰' },
  { id: 'boutique', label: 'Boutique Hotels', emoji: '✨' },
  { id: 'bnb', label: 'Bed & Breakfast', emoji: '🏡' },
  { id: 'budget', label: 'Budget-friendly Hotels', emoji: '💰' },
  { id: 'hostel', label: 'Hostels', emoji: '🛏️' },
  { id: 'camping', label: 'Camping Grounds', emoji: '⛺' },
  { id: 'eco', label: 'Eco-lodges', emoji: '🌱' },
  { id: 'inn', label: 'Inns', emoji: '🏨' },
  { id: 'resort', label: 'Resorts', emoji: '⛰️' },
  { id: 'motel', label: 'Motels', emoji: '🛏️' },
  { id: 'vacation', label: 'Vacation Rentals', emoji: '🏠' },
];

const DINING_OPTIONS = [
  { id: 'fine_dining', label: 'Fine Dining & Gourmet', emoji: '🍽️' },
  { id: 'street_food', label: 'Local Street Food', emoji: '🥘' },
  { id: 'cafes', label: 'Cafes/Bistros', emoji: '🥐' },
  { id: 'family', label: 'Family Restaurants', emoji: '👨‍👩‍👧‍👦' },
  { id: 'vegetarian', label: 'Vegetarian / Vegan Eateries', emoji: '🥬' },
  { id: 'food_trucks', label: 'Food Trucks', emoji: '🚚' },
  { id: 'ethnic', label: 'Ethnic Cuisine', emoji: '🍜' },
  { id: 'farm_table', label: 'Farm-to-Table', emoji: '🌾' },
  { id: 'fast_casual', label: 'Fast Casual', emoji: '🍔' },
  { id: 'pub', label: 'Pub / Tavern Food', emoji: '🍺' },
  { id: 'bakeries', label: 'Bakeries', emoji: '🍰' },
  { id: 'coffee', label: 'Coffee Shops', emoji: '☕' },
];

const TriviaPage = () => {
  const [currentStep, setCurrentStep] = useState(STEPS.WELCOME);
  const [answers, setAnswers] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const [ageError, setAgeError] = useState(false);

  useEffect(() => {
    const loadExistingAnswers = async () => {
      try {
        const response = await getTriviaAnswers();
        if (response.data) {
          setAnswers(response.data);
        }
      } catch (error) {
        console.error('Error loading trivia answers:', error);
      }
    };

    loadExistingAnswers();
  }, []);

  const handleAnswer = async (question, answer) => {
    if (isSaving) return;

    setIsSaving(true);

    try {
      const { _id, createdAt, ...currentAnswers } = answers;
      const updatedAnswers = { ...currentAnswers, [question]: answer };

      setAnswers(updatedAnswers);
      const result = await saveTriviaAnswers(updatedAnswers);

      if (result.success) {
        trackEvent({
          action: 'trivia_answer',
          category: 'Trivia',
          label: question,
          value: 1,
        });
      }
    } catch (error) {
      console.warn('Warning saving answer:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case STEPS.WELCOME:
        return (
          <div className="trivia-welcome">
            <h1>Discover Your Travel Tale! ✈️</h1>
            <p>Embark on a journey to uncover your unique travel personality. Answer these quick questions to discover personalized travel inspirations!</p>
            <button className="cta-button" onClick={() => setCurrentStep(STEPS.BASICS)}>
              Let's Go! <FaArrowRight />
            </button>
          </div>
        );

      case STEPS.BASICS:
        return (
          <div className="trivia-question">
            <h2>Let's start with the basics.</h2>
            <div className="basics-form">
              <div className="form-row">
                <div className="form-group">
                  <label>First name</label>
                  <input
                    type="text"
                    placeholder="First name"
                    value={answers.firstName || ''}
                    onChange={(e) => handleAnswer('firstName', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Last name</label>
                  <input
                    type="text"
                    placeholder="Last name"
                    value={answers.lastName || ''}
                    onChange={(e) => handleAnswer('lastName', e.target.value)}
                  />
                </div>
              </div>
              
              <div className="form-row">
                <div className="form-group">
                  <label>Age</label>
                  <input
                    type="text"
                    placeholder="How many years?"
                    value={answers.age || ''}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow empty input
                      if (value === '') {
                        handleAnswer('age', '');
                        setAgeError(false);
                        return;
                      }
                      
                      // Only validate if it's a number
                      if (/^\d+$/.test(value)) {
                        const age = parseInt(value, 10);
                        if (age >= 14 && age <= 150) {
                          handleAnswer('age', age);
                          setAgeError(false);
                        } else {
                          handleAnswer('age', value);
                          setAgeError(true);
                        }
                      }
                    }}
                  />
                  {ageError && (
                    <p className="error-message">Please enter an age between 14 and 150.</p>
                  )}
                </div>
                <div className="form-group">
                  <label>Sex</label>
                  <div className="radio-group">
                    {['Male', 'Female', 'Other'].map((option) => (
                      <button
                        key={option}
                        className={`radio-button ${answers.sex === option.toLowerCase() ? 'selected' : ''}`}
                        onClick={() => handleAnswer('sex', option.toLowerCase())}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>Location</label>
                <CityAutocomplete
                  value={answers.location || ''}
                  onChange={(selectedLocation) => handleAnswer('location', selectedLocation)}
                />
              </div>

              {renderNavigationButtons(STEPS.MOOD, !answers.firstName || !answers.lastName || !answers.age || !answers.sex || !answers.location || ageError)}
            </div>
          </div>
        );

      case STEPS.MOOD:
        return (
          <div className="trivia-question">
            <h2>What vibe are you feeling today? 😎</h2>
            <div className="options-grid">
              <button
                className={`option-card ${answers.mood === 'curious' ? 'selected' : ''}`}
                onClick={() => handleAnswer('mood', 'curious')}
              >
                <IconContext.Provider value={{ className: 'option-icon' }}>
                  <FaCompass />
                </IconContext.Provider>
                <span>Curious Explorer</span>
                <p>Eager to discover new cultures and places.</p>
              </button>

              <button
                className={`option-card ${answers.mood === 'relaxed' ? 'selected' : ''}`}
                onClick={() => handleAnswer('mood', 'relaxed')}
              >
                <IconContext.Provider value={{ className: 'option-icon' }}>
                  <FaSpa />
                </IconContext.Provider>
                <span>Zen Seeker</span>
                <p>Looking for peace and rejuvenation.</p>
              </button>

              <button
                className={`option-card ${answers.mood === 'adventurous' ? 'selected' : ''}`}
                onClick={() => handleAnswer('mood', 'adventurous')}
              >
                <IconContext.Provider value={{ className: 'option-icon' }}>
                  <FaHiking />
                </IconContext.Provider>
                <span>Thrill Lover</span>
                <p>Excited for adrenaline-pumping experiences.</p>
              </button>

              <button
                className={`option-card ${answers.mood === 'artistic' ? 'selected' : ''}`}
                onClick={() => handleAnswer('mood', 'artistic')}
              >
                <IconContext.Provider value={{ className: 'option-icon' }}>
                  <FaPalette />
                </IconContext.Provider>
                <span>Creative Soul</span>
                <p>Inspired by art and creativity.</p>
              </button>
            </div>
            {renderNavigationButtons(STEPS.DESTINATION, !answers.mood)}
          </div>
        );

      case STEPS.DESTINATION:
        return (
          <div className="trivia-question">
            <h2>Choose your dream destination! 🌅</h2>
            <div className="options-grid">
              <button
                className={`option-card ${answers.destination === 'island' ? 'selected' : ''}`}
                onClick={() => handleAnswer('destination', 'island')}
              >
                <IconContext.Provider value={{ className: 'option-icon' }}>
                  <FaUmbrellaBeach />
                </IconContext.Provider>
                <span>Tropical Island</span>
                <p>Pristine beaches and azure waters.</p>
              </button>

              <button
                className={`option-card ${answers.destination === 'space' ? 'selected' : ''}`}
                onClick={() => handleAnswer('destination', 'space')}
              >
                <IconContext.Provider value={{ className: 'option-icon' }}>
                  <FaUserAstronaut />
                </IconContext.Provider>
                <span>Outer Space</span>
                <p>Beyond the ordinary, into the stars.</p>
              </button>

              <button
                className={`option-card ${answers.destination === 'jungle' ? 'selected' : ''}`}
                onClick={() => handleAnswer('destination', 'jungle')}
              >
                <IconContext.Provider value={{ className: 'option-icon' }}>
                  <FaFeatherAlt />
                </IconContext.Provider>
                <span>Mystical Jungle</span>
                <p>Wildlife and nature's secrets.</p>
              </button>

              <button
                className={`option-card ${answers.destination === 'city' ? 'selected' : ''}`}
                onClick={() => handleAnswer('destination', 'city')}
              >
                <IconContext.Provider value={{ className: 'option-icon' }}>
                  <FaCity />
                </IconContext.Provider>
                <span>Cosmopolitan City</span>
                <p>Urban adventures and lights.</p>
              </button>
            </div>
            {renderNavigationButtons(STEPS.ACTIVITY, !answers.destination)}
          </div>
        );

      case STEPS.ACTIVITY:
        return (
          <div className="trivia-question">
            <h2>Pick an activity that excites you! 🎯</h2>
            <div className="options-grid">
              <button
                className={`option-card ${answers.activity === 'concert' ? 'selected' : ''}`}
                onClick={() => handleAnswer('activity', 'concert')}
              >
                <IconContext.Provider value={{ className: 'option-icon' }}>
                  <FaMusic />
                </IconContext.Provider>
                <span>Live Concert</span>
                <p>Feel the rhythm and vibe.</p>
              </button>

              <button
                className={`option-card ${answers.activity === 'cooking' ? 'selected' : ''}`}
                onClick={() => handleAnswer('activity', 'cooking')}
              >
                <IconContext.Provider value={{ className: 'option-icon' }}>
                  <FaUtensils />
                </IconContext.Provider>
                <span>Cooking Class</span>
                <p>Discover culinary arts.</p>
              </button>

              <button
                className={`option-card ${answers.activity === 'safari' ? 'selected' : ''}`}
                onClick={() => handleAnswer('activity', 'safari')}
              >
                <IconContext.Provider value={{ className: 'option-icon' }}>
                  <FaGlobe />
                </IconContext.Provider>
                <span>Wildlife Safari</span>
                <p>Connect with nature.</p>
              </button>

              <button
                className={`option-card ${answers.activity === 'skydiving' ? 'selected' : ''}`}
                onClick={() => handleAnswer('activity', 'skydiving')}
              >
                <IconContext.Provider value={{ className: 'option-icon' }}>
                  <FaFeatherAlt />
                </IconContext.Provider>
                <span>Skydiving</span>
                <p>Embrace the thrill.</p>
              </button>
            </div>
            {renderNavigationButtons(STEPS.COMPANION, !answers.activity)}
          </div>
        );

      case STEPS.COMPANION:
        return (
          <div className="trivia-question">
            <h2>Who's coming along? 👥</h2>
            <div className="options-grid">
              <button
                className={`option-card ${answers.companion === 'solo' ? 'selected' : ''}`}
                onClick={() => handleAnswer('companion', 'solo')}
              >
                <IconContext.Provider value={{ className: 'option-icon' }}>
                  <FaUser />
                </IconContext.Provider>
                <span>Solo Journey</span>
                <p>Just me, myself, and I.</p>
              </button>

              <button
                className={`option-card ${answers.companion === 'partner' ? 'selected' : ''}`}
                onClick={() => handleAnswer('companion', 'partner')}
              >
                <IconContext.Provider value={{ className: 'option-icon' }}>
                  <FaHeart />
                </IconContext.Provider>
                <span>Significant Other</span>
                <p>Romantic escape.</p>
              </button>

              <button
                className={`option-card ${answers.companion === 'family' ? 'selected' : ''}`}
                onClick={() => handleAnswer('companion', 'family')}
              >
                <IconContext.Provider value={{ className: 'option-icon' }}>
                  <FaChild />
                </IconContext.Provider>
                <span>Family</span>
                <p>Fun for all ages.</p>
              </button>

              <button
                className={`option-card ${answers.companion === 'friends' ? 'selected' : ''}`}
                onClick={() => handleAnswer('companion', 'friends')}
              >
                <IconContext.Provider value={{ className: 'option-icon' }}>
                  <FaUsers />
                </IconContext.Provider>
                <span>Friends</span>
                <p>Unforgettable moments together.</p>
              </button>
            </div>
            {renderNavigationButtons(STEPS.ACCOMMODATION, !answers.companion)}
          </div>
        );

      case STEPS.ACCOMMODATION:
        return (
          <div className="trivia-question">
            <h2>What's your usual accommodation style? 🏨</h2>
            <p className="subtitle">Select any of the following or add your own.</p>
            <div className="options-grid">
              {ACCOMMODATION_OPTIONS.map(option => (
                <button
                  key={option.id}
                  className={`option-card ${answers.accommodation?.includes(option.id) ? 'selected' : ''}`}
                  onClick={() => {
                    const updatedAccommodation = answers.accommodation?.includes(option.id)
                      ? answers.accommodation.filter(i => i !== option.id)
                      : [...(answers.accommodation || []), option.id];
                    handleAnswer('accommodation', updatedAccommodation);
                  }}
                >
                  <span>{option.emoji} {option.label}</span>
                </button>
              ))}
            </div>
            {renderNavigationButtons(STEPS.DINING, !answers.accommodation?.length)}
          </div>
        );

      case STEPS.DINING:
        return (
          <div className="trivia-question">
            <h2>What type of dining experiences do you usually look for? 🍽️</h2>
            <p className="subtitle">Select any of the following or add your own.</p>
            <div className="options-grid">
              {DINING_OPTIONS.map(option => (
                <button
                  key={option.id}
                  className={`option-card ${answers.dining?.includes(option.id) ? 'selected' : ''}`}
                  onClick={() => {
                    const updatedDining = answers.dining?.includes(option.id)
                      ? answers.dining.filter(i => i !== option.id)
                      : [...(answers.dining || []), option.id];
                    handleAnswer('dining', updatedDining);
                  }}
                >
                  <span>{option.emoji} {option.label}</span>
                </button>
              ))}
            </div>
            {renderNavigationButtons(STEPS.INTERESTS, !answers.dining?.length)}
          </div>
        );

      case STEPS.INTERESTS:
        return (
          <div className="trivia-question">
            <h2>What are your interests or favorite things to do while traveling?</h2>
            <p className="subtitle">Select any of the following or add your own.</p>
            <div className="options-grid">
              {Object.keys(INTEREST_ICONS).map(interest => (
                <button
                  key={interest}
                  className={`option-card ${answers.interests?.includes(interest) ? 'selected' : ''}`}
                  onClick={() => {
                    const updatedInterests = answers.interests?.includes(interest)
                      ? answers.interests.filter(i => i !== interest)
                      : [...(answers.interests || []), interest];
                    handleAnswer('interests', updatedInterests);
                  }}
                >
                  <IconContext.Provider value={{ className: 'option-icon' }}>
                    {INTEREST_ICONS[interest]}
                  </IconContext.Provider>
                  <span>{interest}</span>
                </button>
              ))}
              <button
                className="option-card"
                onClick={() => {
                  // Handle "Add your own" functionality
                  // You might want to implement a modal or input field here
                }}
              >
                <IconContext.Provider value={{ className: 'option-icon' }}>
                  <FaPlus />
                </IconContext.Provider>
                <span>Add your own</span>
              </button>
            </div>
            {renderNavigationButtons(STEPS.THANKS, !answers.interests?.length)}
          </div>
        );

      case STEPS.THANKS:
        return (
          <div className="trivia-thanks">
            <IconContext.Provider value={{ className: 'success-icon' }}>
              <FaCheckCircle />
            </IconContext.Provider>
            <h2>All Set! 🎉</h2>
            <p>Thanks for sharing! We'll craft some amazing travel recommendations just for you.</p>
            <button
              className="cta-button"
              onClick={() => navigate('/')}
            >
              Explore Now <FaHomeIcon />
            </button>
          </div>
        );

      default:
        return null;
    }
  };

  // Calculate progress percentage
  const calculateProgress = () => {
    const stepOrder = [
      STEPS.WELCOME,
      STEPS.BASICS,
      STEPS.MOOD,
      STEPS.DESTINATION,
      STEPS.ACTIVITY,
      STEPS.COMPANION,
      STEPS.ACCOMMODATION,
      STEPS.DINING,
      STEPS.INTERESTS,
      STEPS.THANKS,
    ];
    const currentIndex = stepOrder.indexOf(currentStep);
    return ((currentIndex) / (stepOrder.length - 1)) * 100;
  };

  const renderNavigationButtons = (nextStep, disabled = false) => {
    return (
      <div className="navigation-buttons">
        {currentStep !== STEPS.WELCOME && (
          <button
            className="cta-button secondary"
            onClick={() => {
              const stepOrder = [
                STEPS.WELCOME,
                STEPS.BASICS,
                STEPS.MOOD,
                STEPS.DESTINATION,
                STEPS.ACTIVITY,
                STEPS.COMPANION,
                STEPS.ACCOMMODATION,
                STEPS.DINING,
                STEPS.INTERESTS,
                STEPS.THANKS,
              ];
              const currentIndex = stepOrder.indexOf(currentStep);
              setCurrentStep(stepOrder[currentIndex - 1]);
            }}
          >
            Back
          </button>
        )}
        <button
          className="cta-button"
          onClick={() => setCurrentStep(nextStep)}
          disabled={disabled}
        >
          {nextStep === STEPS.THANKS ? 'Finish ' : 'Next '}
          {nextStep === STEPS.THANKS ? <FaCheckCircle /> : <FaArrowRight />}
        </button>
      </div>
    );
  };

  return (
    <div className="trivia-page">
      <div className="trivia-container">
        <div className="progress-bar">
          <div className="progress-bar-fill" style={{ width: `${calculateProgress()}%` }}></div>
        </div>
        {renderStep()}
      </div>
    </div>
  );
};

export default TriviaPage;