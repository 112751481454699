import React, { useState, useEffect } from 'react';
import { getPlaceDetails } from '../utils/serverComm';
import '../styles/PlaceInfoOverlay.css';

const PlaceInfoOverlay = ({ isOpen, onClose, placeInfo }) => {
  const [placeDetails, setPlaceDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      // First make the overlay visible
      setIsVisible(true);
      // Then fetch place details
      const fetchPlaceDetails = async () => {
        if (placeInfo?.googlePlaceId) {
          setLoading(true);
          try {
            const details = await getPlaceDetails(placeInfo.googlePlaceId);
            setPlaceDetails(details);
          } catch (error) {
            console.error('Error fetching place details:', error);
          } finally {
            setLoading(false);
          }
        }
      };
      fetchPlaceDetails();
    } else {
      // When closing, first remove the visible class
      setIsVisible(false);
      // Then reset the details after animation completes
      const timer = setTimeout(() => {
        setPlaceDetails(null);
      }, 300); // Match this with your CSS transition duration
      return () => clearTimeout(timer);
    }
  }, [isOpen, placeInfo]);

  if (!isOpen && !isVisible) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const renderStarRating = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    const stars = [];

    for (let i = 0; i < 5; i++) {
      if (i < fullStars) {
        stars.push(<i key={i} className="fas fa-star" />);
      } else if (i === fullStars && hasHalfStar) {
        stars.push(<i key={i} className="fas fa-star-half-alt" />);
      } else {
        stars.push(<i key={i} className="far fa-star" />);
      }
    }
    return stars;
  };

  return (
    <div 
      className={`place-info-overlay ${isVisible ? 'visible' : ''}`} 
      onClick={handleOverlayClick}
    >
      <div className="place-info-content" onClick={e => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          <i className="fas fa-times"></i>
        </button>

        {loading ? (
          <div className="loading">
            <i className="fas fa-spinner fa-spin"></i> Loading place details...
          </div>
        ) : placeDetails?.updating ? (
          <div className="updating">
            <i className="fas fa-database"></i> We are updating our database...
          </div>
        ) : (
          <>
            {placeDetails?.imageUrl && (
              <div className="place-image-container">
                <img 
                  src={placeDetails.imageUrl} 
                  alt={placeDetails.title || placeInfo?.name}
                  className="place-image"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.style.display = 'none';
                  }}
                />
              </div>
            )}

            <h3>{placeDetails?.title || placeInfo?.name}</h3>

            {placeDetails?.totalScore && (
              <div className="rating">
                {renderStarRating(placeDetails.totalScore)}
                <span>{placeDetails.totalScore}</span>
                {placeDetails.reviewsCount && (
                  <span className="review-count">({placeDetails.reviewsCount} reviews)</span>
                )}
              </div>
            )}

            {placeDetails?.address && (
              <div className="info-row">
                <i className="fas fa-map-marker-alt"></i>
                <span>{placeDetails.address}</span>
              </div>
            )}

            {placeDetails?.openingHours?.find(h => h.day === 'Tuesday')?.hours && (
              <div className="info-row">
                <i className="fas fa-clock"></i>
                <span>Tuesday: {placeDetails.openingHours.find(h => h.day === 'Tuesday').hours}</span>
              </div>
            )}

            {placeDetails?.price && (
              <div className="info-row">
                <i className="fas fa-tag"></i>
                <span>{placeDetails.price}</span>
              </div>
            )}

            {placeDetails?.website && (
              <div className="info-row">
                <i className="fas fa-globe"></i>
                <a href={placeDetails.website} target="_blank" rel="noopener noreferrer">
                  site
                </a>
              </div>
            )}

            {(placeDetails?.permanentlyClosed || placeDetails?.temporarilyClosed) && (
              <div className="warning">
                <i className="fas fa-exclamation-triangle"></i> !!!Warning!!!
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PlaceInfoOverlay;