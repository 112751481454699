import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { AuthContext } from '../contexts/AuthContext';
import { getManualTrip, saveManualTrip } from '../utils/serverComm';
import '../styles/ManualTripPage.css';

const EMPTY_ACTIVITY = {
  activity: '',
  date: '',
  time: '',
  price: '',
  person1: '',
  person2: '',
  ticketsDocs: '',
  workingHour: '',
  menu: '',
  instagram: '',
  appSite: '',
  googleApiLink: '',
  address: '',
  gmapLink: '',
  note01: '',
  note02: '',
};

const ManualTripPage = () => {
  const { tripId, mode } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, loading } = useContext(AuthContext);
  const [activities, setActivities] = useState([]);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [initializing, setInitializing] = useState(true);
  const [destination, setDestination] = useState('Destination');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState(() => {
    const isMobile = window.innerWidth <= 768;
    return Object.keys(EMPTY_ACTIVITY).reduce((acc, key) => ({
      ...acc,
      [key]: isMobile ? key === 'activity' : true
    }), {});
  });

  // Add window resize handler
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      if (isMobile) {
        setVisibleColumns(prev => ({
          ...Object.keys(EMPTY_ACTIVITY).reduce((acc, key) => ({
            ...acc,
            [key]: key === 'activity'
          }), {})
        }));
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (loading) return;

    if (!currentUser || currentUser.isAnonymous) {
      navigate('/', { state: { from: location } });
      return;
    }

    const initializeTrip = async () => {
      try {
        const tripData = await getManualTrip(tripId);
        if (tripData) {
          // Set destination from server data
          if (tripData.destination) {
            setDestination(tripData.destination);
          }
          
          if (tripData.activities && tripData.activities.length > 0) {
            const processedActivities = tripData.activities.map((activity, index) => ({
              ...activity,
              id: activity.id || crypto.randomUUID(),
              order: activity.order || index + 1
            }));
            setActivities(processedActivities);
          } else {
            const initialActivity = {
              ...EMPTY_ACTIVITY,
              id: crypto.randomUUID(),
              order: 1
            };
            await saveManualTrip(tripId, [initialActivity], { destination });
            setActivities([initialActivity]);
          }
        }
      } catch (err) {
        console.error('Error initializing trip:', err);
        setError('Failed to initialize trip. Please try again later.');
      } finally {
        setInitializing(false);
      }
    };

    initializeTrip();
  }, [tripId, currentUser, loading, navigate, location, destination]);

  const handleAddLine = async () => {
    const newActivity = {
      ...EMPTY_ACTIVITY,
      id: crypto.randomUUID(),
      order: activities.length + 1
    };
    
    const newActivities = [...activities, newActivity];
    setActivities(newActivities);

    try {
      setSaving(true);
      await saveManualTrip(tripId, newActivities, { destination });
    } catch (err) {
      console.error('Error adding line:', err);
      setError('Failed to add line. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  const handleInputChange = async (activityId, field, value) => {
    const newActivities = activities.map(activity => {
      if (activity.id === activityId) {
        return { ...activity, [field]: value };
      }
      return activity;
    });
    setActivities(newActivities);

    try {
      setSaving(true);
      await saveManualTrip(tripId, newActivities, { destination });
    } catch (err) {
      console.error('Error saving changes:', err);
      setError('Failed to save changes. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    
    const { source, destination: dest } = result;
    if (source.index === dest.index) return;

    const newActivities = Array.from(activities);
    const [draggedItem] = newActivities.splice(source.index, 1);
    newActivities.splice(dest.index, 0, draggedItem);

    const updatedActivities = newActivities.map((activity, index) => ({
      ...activity,
      order: index + 1
    }));

    setActivities(updatedActivities);

    try {
      setSaving(true);
      await saveManualTrip(tripId, updatedActivities, { destination });
    } catch (err) {
      console.error('Error saving order:', err);
      setError('Failed to save order. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  // Sort activities by order
  const sortedActivities = [...activities].sort((a, b) => a.order - b.order);

  // Add handler for title changes
  const handleTitleChange = async (newTitle) => {
    if (newTitle.trim() === '') newTitle = 'Destination';
    setDestination(newTitle);
    setIsEditingTitle(false);
    
    try {
      setSaving(true);
      // Pass both activities and metadata in the same structure
      await saveManualTrip(tripId, activities, { destination: newTitle });
    } catch (err) {
      console.error('Error saving title:', err);
      setError('Failed to save title. Please try again.');
      // Revert to previous title on error
      setDestination(destination);
    } finally {
      setSaving(false);
    }
  };

  // Add toggle column function
  const toggleColumn = (columnName) => {
    setVisibleColumns(prev => ({
      ...prev,
      [columnName]: !prev[columnName]
    }));
  };

  if (loading || initializing) {
    return (
      <div className="manual-trip-page">
        <Navbar />
        <main className="manual-trip-content">
          <div className="loading-container">
            <l-hatch size="40" stroke="4" speed="4.3" color="black" />
            <p>Loading...</p>
          </div>
        </main>
        <Footer />
      </div>
    );
  }

  if (error) {
    return (
      <div className="manual-trip-page">
        <Navbar />
        <main className="manual-trip-content">
          <p className="error">{error}</p>
          <button onClick={() => window.location.reload()} className="retry-button">
            Retry
          </button>
        </main>
        <Footer />
      </div>
    );
  }

  return (
    <div className="manual-trip-page">
      <Navbar />
      <main className="manual-trip-content">
        <div className="manual-trip-header">
          <div className="title-section">
            <h1>Manual Trip Plan</h1>
            <span className="title-separator">/</span>
            {isEditingTitle ? (
              <input
                type="text"
                className="title-input"
                value={destination}
                onChange={(e) => setDestination(e.target.value)}
                onBlur={(e) => handleTitleChange(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleTitleChange(e.target.value);
                  }
                }}
                autoFocus
              />
            ) : (
              <h1 
                className="editable-title"
                onClick={() => mode === 'edit' && setIsEditingTitle(true)}
              >
                {destination}
                {mode === 'edit' && <span className="edit-icon">✎</span>}
              </h1>
            )}
          </div>
          <div className="header-actions">
            <div className="mode-toggle">
              <button
                className={mode === 'edit' ? 'active' : ''}
                onClick={() => navigate(`/m_trip/${tripId}/edit`)}
              >
                Edit Mode
              </button>
              <button
                className={mode === 'view' ? 'active' : ''}
                onClick={() => navigate(`/m_trip/${tripId}/view`)}
              >
                View Mode
              </button>
            </div>
            {mode === 'edit' && (
              <div className="add-line-wrapper">
                <button className="add-line-btn" onClick={handleAddLine}>
                  + Add Line
                </button>
                <div className={`saving-indicator ${saving ? 'saving' : ''}`}>
                  <span className="saving-text">Saving changes...</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="activities">
            {(provided) => (
              <div className={`table-container ${window.innerWidth <= 768 ? 'mobile-view' : ''}`}>
                <div className="column-toggles">
                  {Object.keys(EMPTY_ACTIVITY).map((field) => (
                    <button
                      key={field}
                      className={`column-toggle ${visibleColumns[field] ? 'active' : ''} ${field === 'activity' && window.innerWidth <= 768 ? 'mobile-locked' : ''}`}
                      onClick={() => field !== 'activity' && toggleColumn(field)}
                      title={visibleColumns[field] ? `Hide ${field}` : `Show ${field}`}
                      disabled={field === 'activity' && window.innerWidth <= 768}
                    >
                      <span className="toggle-icon">{visibleColumns[field] ? '👁️' : '👁️‍🗨️'}</span>
                      {field}
                    </button>
                  ))}
                </div>
                <div
                  className="activities-table"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <div 
                    className="table-header"
                    style={{
                      gridTemplateColumns: `repeat(${Object.values(visibleColumns).filter(Boolean).length}, minmax(150px, 1fr))`
                    }}
                  >
                    {Object.keys(EMPTY_ACTIVITY).map((field) => (
                      visibleColumns[field] && (
                        <div key={field}>{field}</div>
                      )
                    ))}
                  </div>

                  {sortedActivities.map((activity, index) => (
                    <Draggable
                      key={activity.id}
                      draggableId={activity.id}
                      index={index}
                      isDragDisabled={mode !== 'edit'}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={`activity-row ${snapshot.isDragging ? 'dragging' : ''}`}
                          style={{
                            ...provided.draggableProps.style,
                            gridTemplateColumns: `repeat(${Object.values(visibleColumns).filter(Boolean).length}, minmax(150px, 1fr))`
                          }}
                        >
                          {mode === 'edit' && (
                            <div
                              className="drag-handle"
                              {...provided.dragHandleProps}
                            >
                              ⋮
                            </div>
                          )}
                          {Object.keys(EMPTY_ACTIVITY).map((field) => (
                            visibleColumns[field] && (
                              <div key={field} className="activity-cell">
                                {mode === 'edit' ? (
                                  <input
                                    type="text"
                                    value={activity[field] || ''}
                                    onChange={(e) => handleInputChange(activity.id, field, e.target.value)}
                                    placeholder={field}
                                  />
                                ) : (
                                  <span>{activity[field]}</span>
                                )}
                              </div>
                            )
                          ))}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  
                  {mode === 'edit' && (
                    <div 
                      className="activity-row ghost-row" 
                      onClick={handleAddLine}
                      style={{
                        gridTemplateColumns: `repeat(${Object.values(visibleColumns).filter(Boolean).length}, minmax(150px, 1fr))`
                      }}
                    >
                      <div className="add-row-button">
                        <span className="plus-icon">+</span>
                      </div>
                      {Object.keys(EMPTY_ACTIVITY).map((field) => (
                        visibleColumns[field] && (
                          <div key={field} className="activity-cell">
                            <span className="ghost-text">{field}</span>
                          </div>
                        )
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </main>
      <Footer />
    </div>
  );
};

export default ManualTripPage; 