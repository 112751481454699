import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../styles/Contact.css';
import { trackEvent } from '../analytics';

function Contact() {
  const [state, handleSubmit] = useForm("xwpkvaqj"); // Replace with your actual Formspree form ID

  const handleFormSubmit = (e) => {
    handleSubmit(e);
    const reason = e.target.reason.value;
    trackEvent({
      action: 'submit_contact_form',
      category: 'Contact',
      label: `Reason: ${reason}`,
      value: 1,
    });
  };

  if (state.succeeded) {
    return (
      <div className="contact-page">
        <Navbar />
        <main className="main-content">
          <div className="content-block left-column">
            <h1>Contact Us</h1>
            <p>Thank you for reaching out! We'll get back to you shortly.</p>
        </div>
        <div className="content-block right-column">
          <div className="image-container">
            <img
              src="/images/paris-illustration.png"
              alt="Contact illustration"
              className="illustration"
            />
          </div>
        </div>
      </main>
      <Footer />
    </div>
    );
  }

  return (
    <div className="contact-page">
      <Navbar />
      <main className="main-content">
        <div className="content-block left-column">
          <h1>Contact Us</h1>
          <p>
            Have questions or need assistance? Reach out to us! We're here to help plan your perfect journey.
          </p>
          <form className="contact-form" onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label htmlFor="firstName">First name</label>
              <input 
                type="text" 
                id="firstName" 
                name="firstName" 
                placeholder="Your first name" 
                required 
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last name</label>
              <input 
                type="text" 
                id="lastName" 
                name="lastName" 
                placeholder="Your last name" 
                required 
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input 
                type="email" 
                id="email" 
                name="email" 
                placeholder="Your email" 
                required 
              />
              <ValidationError 
                prefix="Email" 
                field="email"
                errors={state.errors}
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone number</label>
              <input 
                type="tel" 
                id="phone" 
                name="phone" 
                placeholder="Your phone number" 
              />
            </div>
            <div className="form-group">
              <label htmlFor="company">Company name & title</label>
              <input 
                type="text" 
                id="company" 
                name="company" 
                placeholder="Company name (if applicable)" 
              />
            </div>
            <div className="form-group">
              <label htmlFor="reason">Reason for reaching out</label>
              <select id="reason" name="reason" required>
                <option value="">Select reason</option>
                <option value="help">Need help</option>
                <option value="partnership">Interested in partnership</option>
                <option value="feedback">Providing feedback</option>
                <option value="creator">Mindtrip Creator inquiry</option>
                <option value="press">Press inquiry</option>
                <option value="other">General inquiry / other</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea 
                id="message" 
                name="message" 
                placeholder="Tell us more..." 
                required
                rows="5"
              ></textarea>
              <ValidationError 
                prefix="Message" 
                field="message"
                errors={state.errors}
              />
            </div>
            <button type="submit" className="cta-button" disabled={state.submitting}>
              {state.submitting ? 'Sending...' : 'Send Message'}
            </button>
          </form>
        </div>
        <div className="content-block right-column">
          <div className="image-container">
            <img
              src="/images/paris-illustration.png"
              alt="Contact illustration"
              className="illustration"
            />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Contact;
  