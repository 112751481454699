import React from 'react';
import '../styles/TripAssistantChat.css';

const TripAssistantChat = () => {
  return (
    <div className="trip-assistant-chat">
      <div className="chat-content">
        <div className="chat-messages">
          <div className="empty-chat-message">
            <p>How can I help you with your trip planning?</p>
            <div className="suggestion-chips">
              <button>Optimize my route</button>
              <button>Suggest restaurants</button>
              <button>Local tips</button>
            </div>
          </div>
        </div>
        
        <div className="chat-input-container">
          <textarea 
            placeholder="Ask me anything about your trip..."
            rows="1"
            className="chat-input"
          />
          <button className="send-message-btn">
            <i className="fas fa-paper-plane"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TripAssistantChat;