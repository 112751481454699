import React, { useEffect, useState, useContext } from 'react';
import Navbar from '../components/Navbar';
import { getTrips, saveManualTrip } from '../utils/serverComm';
import TripCard from '../components/TripCard';
import '../styles/TripsPage.css';
import { AuthContext } from '../contexts/AuthContext';

const TripsPage = () => {
  const { currentUser, loading } = useContext(AuthContext);
  const [trips, setTrips] = useState([]);
  const [loadingTrips, setLoadingTrips] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (loading) return; // Wait until auth is loaded

    if (!currentUser || currentUser.isAnonymous) {
      // Optionally handle unauthorized access
      setError('You must be logged in to view your trips.');
      setLoadingTrips(false);
      return;
    }

    let isMounted = true; // Track if component is mounted

    const fetchTrips = async () => {
      try {
        const tripsData = await getTrips();
        if (isMounted) {
          setTrips(tripsData.trips);
        }
      } catch (err) {
        console.error('Error fetching trips:', err);
        if (isMounted) {
          setError('Failed to load trips. Please try again later.');
        }
      } finally {
        if (isMounted) {
          setLoadingTrips(false);
        }
      }
    };

    fetchTrips();

    return () => {
      isMounted = false;
    };
  }, [currentUser, loading]);

  if (loadingTrips) {
    return (
      <div className="trips-page">
        <Navbar />
        <main className="trips-content">
          <p>Loading your trips...</p>
        </main>
      </div>
    );
  }

  if (error) {
    return (
      <div className="trips-page">
        <Navbar />
        <main className="trips-content">
          <p className="error">{error}</p>
          <button onClick={() => window.location.reload()} className="retry-button">
            Retry
          </button>
        </main>
      </div>
    );
  }

  return (
    <div className="trips-page">
      <Navbar />
      <main className="trips-content">
        <div className="trips-header">
          <h1>Your Trips</h1>
          <button 
            className="add-manual-trip-btn"
            onClick={async () => {
              try {
                const newTripId = crypto.randomUUID();
                // Initialize the trip in the database first
                await saveManualTrip(newTripId, [], { destination: 'New Trip' });
                window.location.href = `/m_trip/${newTripId}/edit`;
              } catch (err) {
                console.error('Error creating new trip:', err);
                setError('Failed to create new trip. Please try again.');
              }
            }}
          >
            + Add Manual Trip
          </button>
        </div>
        {trips.length === 0 ? (
          <p>You have no trips yet. Start planning your adventure!</p>
        ) : (
          <div className="trips-list">
            {trips.map((trip) => (
              <TripCard key={trip._id} trip={trip} />
            ))}
          </div>
        )}
      </main>
    </div>
  );
};

export default TripsPage;
