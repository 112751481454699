import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Added useNavigate
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'; // Importing react-beautiful-dnd
import Navbar from '../components/Navbar';
import MapComponent from '../components/MapComponent';
import { getTripDetails, addDaysToTrip, updateTripSettings, createTrip, updateItinerary } from '../utils/serverComm'; // Imported updateItinerary
import '../styles/TravelPlanPage.css';
import CurrentSettings from '../components/CurrentSettings'; // Import CurrentSettings
import { hatch } from 'ldrs';
import html2pdf from 'html2pdf.js';
import Footer from '../components/Footer'; // Ensure Footer is imported
import { v4 as uuidv4 } from 'uuid'; // Imported uuidv4
import { trackEvent } from '../analytics';
import PlaceInfoOverlay from '../components/PlaceInfoOverlay';
import DOMPurify from 'dompurify';
import PlaceInfoPopup from '../components/PlaceInfoPopup';
import TripAssistantChat from '../components/TripAssistantChat'; // Add this import

// Initialize the loader
hatch.register();

function TravelPlanPage() {
  const { tripId } = useParams();
  const navigate = useNavigate(); // Initialize navigate
  const [tripData, setTripData] = useState(null);
  const [itinerary, setItinerary] = useState([]); // Separate itinerary state
  const [expandedDays, setExpandedDays] = useState({});
  const [truncatedDescriptions, setTruncatedDescriptions] = useState({});
  const descriptionRefs = useRef({});
  const [persons, setPersons] = useState(null);
  const [style, setStyle] = useState('');
  const [budget, setBudget] = useState(null);
  const [days, setDays] = useState(null); // Initialize to null
  const [mapPlaces, setMapPlaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMoreDays, setLoadingMoreDays] = useState(false);
  const [error, setError] = useState(null); // New error state
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [hoverInfo, setHoverInfo] = useState(null);

  // Update initial state based on screen width
  const [isCustomizeOpen, setIsCustomizeOpen] = useState(window.innerWidth >= 769);

  // Add effect to handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsCustomizeOpen(window.innerWidth >= 769);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // New state for settings
  const [settings, setSettings] = useState({
    notFirstTime: false,
    persons: 1,
    kids: false,
    travelSupport: false,
    budget: 0,
    currency: 'USD',
  });

  useEffect(() => {
    const fetchTrip = async () => {
      try {
        setLoading(true);
        const data = await getTripDetails(tripId);
        console.log('Raw description from backend:', data.plan?.itinerary[0]?.description);
        setTripData(data);
        setItinerary(data.plan?.itinerary || []); // Safeguard itinerary
        setDays(data.duration || 0); // Safeguard days

        // Set current settings
        setSettings({
          notFirstTime: data.advancedSettings?.notFirstTime || false,
          persons: data.advancedSettings?.persons || 1,
          kids: data.advancedSettings?.kids || false,
          travelSupport: data.advancedSettings?.travelSupport || false,
          budget: data.advancedSettings?.budget || 0,
          currency: data.advancedSettings?.currency || 'USD',
        });

        // Prepare places for the map in the order of the itinerary
        const places = [];
        (data.plan?.itinerary || []).forEach(day => {
          (day.activities || []).forEach(activity => {
            if (activity.validate && activity.placeId !== 'none') { // Ensure using placeId consistent with serverComm.js
              places.push({
                name: activity.place_name,
                placeId: activity.placeId,
                location: activity.location
              });
            }
          });
        });
        console.log('Map Places:', places);
        setMapPlaces(places);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching trip data:', error);
        setError('Failed to load trip data. Please try again later.');
        setTripData(null);
        setItinerary([]);
        setMapPlaces([]);
        setLoading(false);
      }
    };

    if (tripId) {
      fetchTrip();
    }
  }, [tripId]);

  useEffect(() => {
    const handleResize = () => {
      const newTruncatedDescriptions = {};
      if (Array.isArray(itinerary) && itinerary.length > 0) {
        itinerary.forEach((day) => {
          const descriptionElement = descriptionRefs.current[day.day];
          if (descriptionElement) {
            newTruncatedDescriptions[day.day] = descriptionElement.scrollWidth > descriptionElement.clientWidth;
          }
        });
        setTruncatedDescriptions(newTruncatedDescriptions);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initial state

    return () => window.removeEventListener('resize', handleResize);
  }, [itinerary]);

  const toggleDayExpansion = (day) => {
    trackEvent({
      action: 'toggle',
      category: 'TravelPlanPage',
      label: `Expand Day ${day} ${expandedDays[day] ? 'Collapse' : 'Expand'}`,
      value: 1,
    });

    setExpandedDays((prev) => ({ ...prev, [day]: !prev[day] }));
  };

  const getTransportIcon = (type) => {
    switch (type) {
      case 'walking':
        return 'fa-walking';
      case 'driving':
        return 'fa-car';
      case 'bicycling':
        return 'fa-bicycle';
      case 'transit':
        return 'fa-bus';
      default:
        return 'fa-arrow-down';
    }
  };

  const generatePDF = () => {
    trackEvent({
      action: 'click',
      category: 'TravelPlanPage',
      label: 'Print Button',
      value: 1,
    });

    // Create a clone of the content we want to print
    const printContent = document.createElement('div');
    printContent.innerHTML = `
      <div class="pdf-content">
        <h1>${tripData.destinationCity}, ${tripData.destinationCountry}</h1>
        ${itinerary.map((day, index) => `
          <div class="pdf-day">
            <h2>Day ${day.day}: ${day.title}</h2>
            <p>${day.description}</p>
            ${day.activities.map(activity => `
              <div class="pdf-activity">
                <strong>${activity.start_time}</strong> - ${activity.description}
                ${activity.travel_to_next ? `
                  <div class="pdf-logistics">
                    ${Object.entries(activity.travel_to_next).map(([type, data]) => `
                      <div>${type}: ${data.time} (${data.distance})</div>
                    `).join('')}
                  </div>
                ` : ''}
              </div>
            `).join('')}
          </div>
        `).join('')}
      </div>
    `;

    // Configure PDF options
    const options = {
      margin: 10,
      filename: `${tripData.destinationCity}_travel_plan.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };

    // Generate PDF
    html2pdf().from(printContent).set(options).save();
  };

  // Function to load additional days
  const loadAdditionalDays = async () => {
    if (!tripData || !days) return; // Ensure days is set

    const existingDays = itinerary.length;
    const remainingDays = days - existingDays;

    if (remainingDays <= 0) return;
  
    setLoadingMoreDays(true);
    try {
      const additionalPlan = await addDaysToTrip(tripId, existingDays + 1, remainingDays);
      const updatedItinerary = [...itinerary, ...additionalPlan.itinerary];
      setItinerary(updatedItinerary); // Update separate itinerary state

      // Update tripData.plan.itinerary if needed
      setTripData((prevData) => ({
        ...prevData,
        plan: {
          ...prevData.plan,
          itinerary: updatedItinerary,
        },
      }));

      // Update map places
      const newPlaces = additionalPlan.itinerary.flatMap(day =>
        (day.activities || []).filter(activity => activity.validate && activity.placeId !== 'none').map(activity => ({
          name: activity.place_name,
          placeId: activity.placeId,
          location: activity.location,
        }))
      );

      setMapPlaces((prevPlaces) => [...prevPlaces, ...newPlaces]);
    } catch (error) {
      console.error('Error loading additional days:', error);
      // Optionally, show a user-friendly message
    } finally {
      setLoadingMoreDays(false);
    }
  };

  useEffect(() => {
    if (tripData && days) {
      // Automatically load additional days if duration exceeds initial load
      loadAdditionalDays();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripData, days]);

  // Function to handle settings change
  const handleSettingsChange = async (updatedSettings) => {
    setSettings(updatedSettings);
    try {
      await updateTripSettings(tripId, updatedSettings);
      setTripData((prevData) => ({
        ...prevData,
        advancedSettings: updatedSettings,
      }));
      console.log('Trip settings updated successfully.');
      trackEvent({
        action: 'update_settings',
        category: 'TravelPlanPage',
        label: 'Settings Updated',
        value: 1,
      });
    } catch (error) {
      console.error('Error updating trip settings:', error);
      // Optionally, revert to previous settings or show an error message
    }
  };

  // Function to handle itinerary update (e.g., after drag-and-drop)
  const handleItineraryUpdate = async (updatedItinerary) => {
    try {
      const result = await updateItinerary(tripId, updatedItinerary);
      return result;
    } catch (error) {
      console.error('Error updating itinerary:', error);
      throw error;
    }
  };

  // New function to handle "Try Again" button click
  const handleTryAgain = async () => {
    trackEvent({
      action: 'click',
      category: 'TravelPlanPage',
      label: 'Try Again Button',
      value: 1,
    });

    if (!tripData) {
      alert('Current trip data is unavailable.');
      return;
    }

    const { destinationCity, destinationCountry } = tripData;
    const newTripId = uuidv4();

    // Store trip initiation status in sessionStorage
    sessionStorage.setItem('tripStatus', JSON.stringify({
      tripId: newTripId,
      status: 'initiated',
    }));

    // Navigate to LoadingPage first
    navigate(`/trip/${newTripId}/loading`, { 
      state: { 
        tripId: newTripId,
        destinationCity, 
        destinationCountry, 
        duration: days,
        advancedSettings: settings 
      } 
    });

    try {
      // Create trip after navigating to LoadingPage
      const result = await createTrip(newTripId, destinationCity, destinationCountry, days, settings);
      console.log('New trip created:', result);

      // Update trip status to 'completed'
      sessionStorage.setItem('tripStatus', JSON.stringify({
        tripId: newTripId,
        status: 'completed',
      }));

      // Navigate to TravelPlanPage after trip is created
      navigate(`/trip/${newTripId}/edit`);

      // Optionally, remove tripStatus from sessionStorage if no longer needed
      sessionStorage.removeItem('tripStatus');
    } catch (error) {
      console.error('Error creating new trip:', error);
      // Handle error (e.g., show error message to user)

      // Update trip status to 'error'
      sessionStorage.setItem('tripStatus', JSON.stringify({
        tripId: newTripId,
        status: 'error',
      }));

      alert('Failed to create a new trip. Please try again.');
      navigate('/'); // Redirect to LandingPage or appropriate page
    }
  };

  // Handler function for drag end
  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // Clone the itinerary to avoid mutating state directly
    const newItinerary = JSON.parse(JSON.stringify(itinerary));

    // Find the source and destination days
    const sourceDayIndex = newItinerary.findIndex(
      (day) => day.day.toString() === source.droppableId
    );
    const destinationDayIndex = newItinerary.findIndex(
      (day) => day.day.toString() === destination.droppableId
    );

    if (sourceDayIndex === -1 || destinationDayIndex === -1) return;

    // Remove the moved activity from the source
    const [movedActivity] = newItinerary[sourceDayIndex].activities.splice(source.index, 1);

    // Insert the activity into the destination
    newItinerary[destinationDayIndex].activities.splice(destination.index, 0, movedActivity);

    // Recalculate order numbers for all activities
    let globalOrder = 1;
    newItinerary.forEach(day => {
      day.activities.forEach(activity => {
        activity.order = globalOrder++;
      });
    });

    // Update state immediately with the new order
    setItinerary(newItinerary);

    // Save changes to backend
    try {
      await updateItinerary(tripId, newItinerary);
      
      // Update mapPlaces state to reflect the new order
      const updatedMapPlaces = [];
      newItinerary.forEach(day => {
        day.activities.forEach(activity => {
          if (activity.validate && activity.placeId !== 'none') {
            updatedMapPlaces.push({
              name: activity.place_name,
              placeId: activity.placeId,
              location: activity.location,
              order: activity.order,
            });
          }
        });
      });
      setMapPlaces(updatedMapPlaces);

      trackEvent({
        action: 'drag_drop',
        category: 'TravelPlanPage',
        label: `Move Activity from Day ${sourceDayIndex + 1} to Day ${destinationDayIndex + 1}`,
        value: 1,
      });
    } catch (error) {
      console.error('Failed to update itinerary:', error);
      // Revert the UI state on error
      const response = await getTripDetails(tripId);
      setItinerary(response.plan.itinerary);
      // Show error message to user
      setError('Failed to save changes. Please try again.');
    }
  };

  // Add this function to handle info button click
  const handleInfoClick = (activity) => {
    if (activity.googlePlaceId) {
      setSelectedPlace({
        name: activity.place_name,
        googlePlaceId: activity.googlePlaceId
      });
    }
  };

  // Modify the createSanitizedMarkup function
  const createSanitizedMarkup = (html, activity) => {
    if (!html) return { __html: '' };
    
    // Add hover events to bold tags, ensure googlePlaceId exists
    const modifiedHtml = html.replace(
      /<b>(.*?)<\/b>/g,
      (match, text) => {
        // Only add hover attributes if activity has a googlePlaceId
        if (activity?.googlePlaceId) {
          return `<b class="hoverable" 
            data-place-id="${activity.googlePlaceId}"
            data-is-place="true"
          >${text}</b>`;
        }
        return match; // Return original match if no googlePlaceId
      }
    );
    
    return { __html: DOMPurify.sanitize(modifiedHtml, {
      ADD_ATTR: ['data-place-id', 'data-is-place'] // Allow our custom attributes
    }) };
  };

  // Update the hover event handlers
  useEffect(() => {
    const handleMouseOver = (e) => {
      const hoverableElement = e.target.closest('b[data-is-place="true"]');
      if (hoverableElement) {
        const placeId = hoverableElement.getAttribute('data-place-id');
        if (placeId) {
          const rect = hoverableElement.getBoundingClientRect();
          setHoverInfo({
            googlePlaceId: placeId,
            position: {
              x: rect.left,
              y: rect.bottom + 5
            }
          });
        }
      }
    };

    const handleMouseOut = (e) => {
      // Find the closest hoverable element
      const hoverableElement = e.target.closest('b[data-is-place="true"]');
      if (hoverableElement) {
        setHoverInfo(null);
      }
    };

    // Add event listeners to the document
    document.addEventListener('mouseover', handleMouseOver);
    document.addEventListener('mouseout', handleMouseOut);

    return () => {
      // Clean up event listeners
      document.removeEventListener('mouseover', handleMouseOver);
      document.removeEventListener('mouseout', handleMouseOut);
    };
  }, []);

  if (loading) {
    return (
      <div className="loading-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <l-hatch
          size="40"
          stroke="4"
          speed="4.3" 
          color="black"
        ></l-hatch>
      </div>
    );
  }

  if (error) {
    return <div className="error-message">{error}</div>; // Display error message
  }

  if (!tripData) {
    return <div>No trip data available.</div>;
  }

  const { destinationCity, destinationCountry, plan } = tripData;

  // Update toggle handler to only work on mobile
  const handleCustomizeToggle = () => {
    trackEvent({
      action: 'toggle',
      category: 'TravelPlanPage',
      label: `Customize Your Plan ${isCustomizeOpen ? 'Opened' : 'Closed'}`,
      value: 1,
    });

    if (window.innerWidth < 769) {
      setIsCustomizeOpen(!isCustomizeOpen);
    }
  };

  return (
    <div className="travel-plan-page">
      <Navbar />
      <main className="travel-plan-content">
        {/* Top Section */}
        <div className="top-section">
          <div className="left-column">
            <div 
              className="destination-image"
              style={{
                backgroundImage: tripData?.plan?.backgroundImage 
                  ? `url(${tripData.plan.backgroundImage})`
                  : 'url(/images/default-destination.png)'
              }}
            >
              <h1>
                Your trip to {destinationCity}
                <span className="destination-country">{destinationCountry}</span>
              </h1>
            </div>
            <TripAssistantChat />
          </div>
          <div className="right-column">
            <div className={`right-column-content ${isCustomizeOpen ? 'open' : ''}`}>
              {/* Customize Your Plan Header with Toggle Icon */}
              <div className="customize-header" onClick={handleCustomizeToggle}>
                <h3>Customize Your Plan</h3>
                {/* Only show toggle icon on mobile */}
                {window.innerWidth < 769 && (
                  <i className={`fas ${isCustomizeOpen ? 'fa-chevron-up' : 'fa-chevron-down'} toggle-icon`}></i>
                )}
              </div>
              {/* Current Settings */}
              <CurrentSettings
                settings={settings}
                onSettingsChange={handleSettingsChange}
              />
              {/* Action Buttons */}
              <div className="action-buttons">
                <button className="action-btn try-again-btn" onClick={handleTryAgain}>Try Again</button> {/* Added onClick handler */}
                <button 
                  className="action-btn save-edit-btn" 
                  onClick={() => trackEvent({
                    action: 'click',
                    category: 'TravelPlanPage',
                    label: 'Save/Edit Button',
                    value: 1,
                  })}
                >
                  Save / Edit
                </button>
                <button className="action-btn print-btn" onClick={generatePDF}>
                  <i className="fas fa-file-pdf"></i>Print
                </button>
              </div>
            </div>
          </div>
        </div>



        {/* Bottom Section */}
        <div className="bottom-section">
          <div className="left-column">
            <div className="daily-plan">
              <h2>Daily Itinerary</h2>
              <DragDropContext onDragEnd={onDragEnd}>
                {itinerary.map((day) => (
                  <Droppable droppableId={day.day.toString()} key={day.day}>
                    {(provided) => (
                      <div
                        className="day-plan"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {/* Day header and description */}
                        <div className="day-header">
                          <div className="day-title-weather">
                            <h3 className="day-title">{day.title || `Day ${day.day}`}:</h3>
                            <div className="day-weather">
                              {plan.temperature}°C <i className={`fas ${plan.weather === "sunny-with-cloudy" ? "fa-cloud-sun" : "fa-sun"}`}></i>
                            </div>
                          </div>
                          <button 
                            className={`expand-btn ${expandedDays[day.day] ? 'expanded' : ''}`} 
                            onClick={() => toggleDayExpansion(day.day)}
                          >
                            <i className="fas fa-chevron-down"></i>
                          </button>
                        </div>
                        {/* Day description */}
                        <p
                          ref={(el) => (descriptionRefs.current[day.day] = el)}
                          className={`day-description ${expandedDays[day.day] ? 'expanded' : ''}`}
                          title={truncatedDescriptions[day.day] ? day.description : ''}
                          dangerouslySetInnerHTML={createSanitizedMarkup(day.description, day)}
                        />
                        <div className="day-separator"></div>
                        {/* Day budget */}
                        {day.budget && (
                          <div className="day-budget">
                            <span><i className="fas fa-utensils"></i> ${day.budget.Meal}</span>
                            <span><i className="fas fa-ticket-alt"></i> ${day.budget.Activities}</span>
                            <span><i className="fas fa-bus"></i> ${day.budget.Transport}</span>
                          </div>
                        )}
                        {expandedDays[day.day] && day.activities && day.activities.length > 0 && (
                          <div className="day-activities">
                            {day.activities
                              .sort((a, b) => a.order - b.order)
                              .map((activity, activityIndex) => (
                                <React.Fragment key={activity.id}>
                                  <Draggable
                                    key={activity.id}
                                    draggableId={activity.id}
                                    index={activityIndex}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        className={`activity ${snapshot.isDragging ? 'dragging' : ''}`}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          ...provided.draggableProps.style,
                                        }}
                                      >
                                        {/* Activity content */}
                                        <i className={`fas fa-${activity.icon || 'map-marker-alt'}`}></i>
                                        <div className="activity-time">
                                          <span className="activity-time-main">
                                            {activity.start_time.split(' ')[0]}
                                          </span>
                                          <span className="activity-time-period">
                                            {activity.start_time.split(' ')[1]}
                                          </span>
                                        </div>
                                        <span 
                                          className="activity-description"
                                          dangerouslySetInnerHTML={createSanitizedMarkup(activity.description, activity)}
                                        />
                                        <button 
                                          className="info-button"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleInfoClick(activity);
                                          }}
                                        >
                                          <i className="fas fa-info-circle"></i>
                                        </button>
                                        <div className="drag-handle" aria-label="Drag to reorder"></div>
                                        <span className="activity-number">{activity.order}</span>
                                      </div>
                                    )}
                                  </Draggable>
                                  {/* Render travel_to_next information */}
                                  {activity.travel_to_next && (
                                    <div className="logistics">
                                      {Object.entries(activity.travel_to_next).map(([type, data]) => (
                                        <div key={type} className="logistics-option">
                                          <i className={`fas ${getTransportIcon(type)}`}></i>
                                          <span>
                                            {data.time} ({data.distance})
                                            {type === 'walking' && data.steps && (
                                              <span className="steps-count">
                                                {' '}• ~{data.steps} steps
                                              </span>
                                            )}
                                          </span>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </React.Fragment>
                              ))}
                            {provided.placeholder}
                          </div>
                        )}
                        {/* Show loading animation for days not yet loaded */}
                        {!day.title && (
                          <div className="day-loading">
                            <l-hatch
                              size="20"
                              stroke="2"
                              speed="3.0" 
                              color="gray"
                            ></l-hatch>
                            <span>Loading day details...</span>
                          </div>
                        )}
                      </div>
                    )}
                  </Droppable>
                ))}
              </DragDropContext>
              {/* Button to manually load remaining days if desired */}
              {loadingMoreDays && (
                <div className="additional-loading">
                  <l-hatch
                    size="30"
                    stroke="3"
                    speed="4.0" 
                    color="black"
                  ></l-hatch>
                  <span>Loading more days...</span>
                </div>
              )}
            </div>
          </div>
          <div className="right-column">
            <div className="map-container">
              <MapComponent 
                destination={`${destinationCity}, ${destinationCountry}`} 
                itinerary={itinerary} // Pass the updated itinerary
              />
            </div>
          </div>
        </div>
      </main>
      <Footer /> {/* Add Footer component here */}
      <PlaceInfoOverlay
        isOpen={selectedPlace !== null}
        onClose={() => setSelectedPlace(null)}
        placeInfo={selectedPlace}
      />
      {/* Add the popup component */}
      {hoverInfo && (
        <PlaceInfoPopup
          googlePlaceId={hoverInfo.googlePlaceId}
          position={hoverInfo.position}
        />
      )}
    </div>
  );

}

export default TravelPlanPage;
