import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { hatch } from 'ldrs';

// Initialize the loader
hatch.register();

/**
 * PrivateRoute ensures that only authenticated users can access the wrapped component.
 * If not authenticated, it redirects to the landing page.
 *
 * @param {React.Component} children - The component to render if authenticated.
 * @returns {React.Component} - The rendered component or a redirect.
 */
const PrivateRoute = ({ children }) => {
  const { currentUser, loading } = useContext(AuthContext);
  const location = useLocation();

  if (loading) {
    return (
      <div className="loading-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <l-hatch
          size="40"
          stroke="4"
          speed="4.3" 
          color="black"
        ></l-hatch>
      </div>
    );
  }

  // Allow access to TravelPlanPage and LoadingPage for all users (including anonymous)
  if (location.pathname.includes('/trip/') && (location.pathname.includes('/edit') || location.pathname.includes('/loading'))) {
    return children;
  }

  // For manual trip pages and other protected routes, require authenticated non-anonymous users
  if (!currentUser || currentUser.isAnonymous) {
    // Redirect to landing page but save the attempted URL
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
};

export default PrivateRoute;
