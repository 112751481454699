import React, { useState, useEffect } from 'react';
import { getPlaceDetails } from '../utils/serverComm';
import '../styles/PlaceInfoPopup.css';

const PlaceInfoPopup = ({ googlePlaceId, position }) => {
  const [placeDetails, setPlaceDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlaceDetails = async () => {
      if (googlePlaceId) {
        try {
          const details = await getPlaceDetails(googlePlaceId);
          setPlaceDetails(details);
        } catch (error) {
          console.error('Error fetching place details:', error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchPlaceDetails();
  }, [googlePlaceId]);

  if (!position) return null;

  return (
    <div 
      className="place-info-popup"
      style={{
        position: 'fixed',
        left: `${position.x}px`,
        top: `${position.y}px`
      }}
    >
      {loading ? (
        <div className="popup-loading">Loading...</div>
      ) : (
        <div className="popup-content">
          {placeDetails?.imageUrl && (
            <img 
              src={placeDetails.imageUrl} 
              alt={placeDetails.title}
              className="popup-image"
            />
          )}
          <h4>{placeDetails?.title}</h4>
          {placeDetails?.totalScore && (
            <div className="popup-rating">
              {[...Array(5)].map((_, i) => (
                <i 
                  key={i}
                  className={`fas fa-star ${i < placeDetails.totalScore ? 'filled' : ''}`}
                />
              ))}
              <span>{placeDetails.totalScore}</span>
            </div>
          )}
          {placeDetails?.address && (
            <div className="popup-info">
              <i className="fas fa-map-marker-alt"></i>
              <span>{placeDetails.address}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PlaceInfoPopup;