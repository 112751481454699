import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import LandingPage from './pages/LandingPage';
import LoadingPage from './pages/LoadingPage';
import TravelPlanPage from './pages/TravelPlanPage';
import TravelPlanPageMockup from './pages/TravelPlanPageMockup';
import TripsPage from './pages/TripsPage';
import PrivateRoute from './components/PrivateRoute';
import CookieConsent from './components/CookieConsent';
import './styles/CookieConsent.css';

// Import pages
import About from './pages/About';
import Contact from './pages/Contact';
import Help from './pages/Help';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import CookiesPolicy from './pages/CookiesPolicy';
import ChallengePage from './pages/ChallengePage';
import TriviaPage from './pages/TriviaPage';
import ManualTripPage from './pages/ManualTripPage';
import ManualTripPreviewPage from './pages/ManualTripPreviewPage';
import AudioGuidePage from './pages/AudioGuidePage';
import AudioGuidePage_v2 from './pages/AudioGuidePage_v2';
import AudioGuidePage_v3 from './pages/AudioGuidePage_v3';
// Import analytics utility
import { trackPageView } from './analytics';

// Import NotFoundPage for 404
import NotFoundPage from './pages/404';

function App() {
  return (
    <Router>
      <AnalyticsListener />
      <div className="App">
        <Routes>
          {/* Existing Routes */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/trip/:tripId/loading" element={<LoadingPage />} />
          <Route path="/trip/:tripId/edit" element={<TravelPlanPage />} />
          <Route path="/plan-mockup" element={<TravelPlanPageMockup />} />

          {/* Protected Routes */}
          <Route
            path="/trips"
            element={
              <PrivateRoute>
                <TripsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/m_trip/:tripId/:mode"
            element={
              <PrivateRoute>
                <ManualTripPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/m_trip/:tripId/preview"
            element={
              <PrivateRoute>
                <ManualTripPreviewPage />
              </PrivateRoute>
            }
          />

          {/* Audio Guide Route */}
          <Route path="/audio_guide" element={<AudioGuidePage />} />
          <Route path="/audio_guide_v2" element={<AudioGuidePage_v2 />} />
          <Route path="/audio_guide_v3" element={<AudioGuidePage_v3 />} />
          {/* Public Routes */}
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/help" element={<Help />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          <Route path="/challenge" element={<ChallengePage />} />
          <Route
            path="/trivia"
            element={
              <PrivateRoute>
                <TriviaPage />
              </PrivateRoute>
            }
          />

          {/* Fallback Route for 404 Not Found */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        enableDeclineButton
        cookieName="talesTravelCookieConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        declineButtonStyle={{ color: "#ffffff", background: "#6200ea" }}
        expires={150}
      >
        We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.{" "}
        <a href="/cookies-policy" style={{ color: "#fff", textDecoration: "underline" }}>Learn More</a>
      </CookieConsent>
    </Router>
  );
}

// Component to listen for route changes and track page views
function AnalyticsListener() {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  return null;
}

export default App;
