import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { getManualTrip } from '../utils/serverComm';
import '../styles/ManualTripPage.css';

const ManualTripPreviewPage = () => {
  const [tripData, setTripData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [uniqueDates, setUniqueDates] = useState([]);
  const { tripId } = useParams();
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTripData = async () => {
      try {
        const data = await getManualTrip(tripId);
        if (!data || (
          Array.isArray(data.userId) 
            ? !data.userId.includes(currentUser?.uid)
            : data.userId !== currentUser?.uid
        )) {
          navigate('/');
          return;
        }
        
        // Sort activities by order
        const sortedActivities = data.activities.sort((a, b) => a.order - b.order);
        
        // Extract and format unique dates
        const dates = [...new Set(sortedActivities
          .filter(activity => activity.date)
          .map(activity => activity.date))]
          .sort((a, b) => new Date(a) - new Date(b));
        
        setUniqueDates(dates);
        setSelectedDate(dates[0]);
        setTripData({ ...data, activities: sortedActivities });
      } catch (error) {
        console.error('Error fetching trip data:', error);
        navigate('/');
      }
    };

    fetchTripData();
  }, [tripId, currentUser, navigate]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const renderIcon = (type, link) => {
    if (!link) return null;
    
    const icons = {
      menu: 'fas fa-utensils',
      instagram: 'fab fa-instagram',
      site: 'fas fa-globe',
      appSite: 'fas fa-brands fa-internet-explorer',
      googleApiLink: 'fas fa-directions',
      gmapLink: 'fas fa-map-marker-alt',
      person1: 'fas fa-person',
      person2: 'fas fa-person-dress'
    };
    return (
      <a href={link} target="_blank" rel="noopener noreferrer" className="icon-link">
        <i className={icons[type]}></i>
      </a>
    );
  };

  if (!tripData) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    <div className="manual-trip-preview">
      <header className="preview-header">
        <h1>{tripData.title}</h1>
        {tripData.destination && (
          <h2 className="destination">
            {tripData.destination}
          </h2>
        )}
        {tripData.description && <p className="trip-description">{tripData.description}</p>}
      </header>

      <div className="date-selector">
        {uniqueDates.map(date => (
          <button
            key={date}
            className={`date-button ${selectedDate === date ? 'active' : ''}`}
            onClick={() => setSelectedDate(date)}
          >
            {new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
          </button>
        ))}
      </div>

      <div className="activities-container">
        {selectedDate && (
          <div className="date-section">
            <h2 className="date-header">{formatDate(selectedDate)}</h2>
            {tripData.activities
              .filter(activity => activity.date === selectedDate && activity.activity)
              .map((activity, index) => (
                <div key={index} className="activity-card">
                  <div className="activity-time">
                    <span className="time">
                      <i className="far fa-clock"></i> {activity.time || "--:--"}
                    </span>
                  </div>
                  
                  <div className="activity-content">
                    <h3 className="activity-title">{activity.activity}</h3>
                    
                    {activity.location && (
                      <p className="location">
                        <i className="fas fa-map-marker-alt"></i> {activity.location}
                      </p>
                    )}

                    {activity.address && (
                      <p className="address">
                        <i className="fas fa-map-pin"></i> {activity.address}
                      </p>
                    )}

                    {activity.workingHours && (
                      <p className="working-hours">
                        <i className="far fa-clock"></i> {activity.workingHours}
                      </p>
                    )}

                    {activity.notes && <p className="notes">{activity.notes}</p>}
                    {activity.note01 && <p className="notes note01">{activity.note01}</p>}
                    {activity.note02 && <p className="notes note02">{activity.note02}</p>}
                    
                    <div className="activity-links">
                      {renderIcon('menu', activity.menu)}
                      {renderIcon('instagram', activity.instagram)}
                      {renderIcon('site', activity.site)}
                      {renderIcon('appSite', activity.appSite)}
                      {renderIcon('googleApiLink', activity.googleApiLink)}
                      {renderIcon('gmapLink', activity.gmapLink)}
                      {renderIcon('person1', activity.person1)}
                      {renderIcon('person2', activity.person2)}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ManualTripPreviewPage; 